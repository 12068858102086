(function($) {
	$.fn.deepLinks = function(options) {
		return $(this).on({
			click: function() {
				var link = $(this).attr('href').split('//')[1],
					w1 = window;

				if($(this).is('[target="_blank"]')) {
					w1 = window.open($(this).attr('href'));
				}
				else {
					w1.location = $(this).attr('href');
				}

				setTimeout(function () {
					w1.location = "https://"+link;
				}, 25);

				return false;
			}
		}, 'a[href*="://"]:not([href^="http"])');
	};
})(jQuery);