(function objectfitPolyfill(f){ /in/.test(document.readyState)?setTimeout(objectfitPolyfill,5,f) : f()})(polyfillObjectfit = function() {
	// Detect objectFit support
	if('objectFit' in document.documentElement.style === false) {
		// assign HTMLCollection with parents of images with objectFit to variable
		var container = document.querySelectorAll('[data-objectfit]');

		// Loop through HTMLCollection
		for(var i = 0; i < container.length; i++) {
			var img = container[i].querySelector('img');

			if(!img) {
				continue;
			}

			// Asign image source to variable
			var imageSource = img.src;

			// Replace image with svg
			img.src = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'' + img.width + '\' height=\'' + img.height + '\'%3E%3C/svg%3E';

			// add background settings
			Object.assign(img.style, {
				backgroundSize: img.getAttribute('data-objectfit') || 'cover',
				backgroundImage: 'url(' + imageSource + ')',
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat'
			});
		}
	}
});