(function placeholderPolyfill(f){ /in/.test(document.readyState)?setTimeout(placeholderPolyfill,5,f) : f()})(polyfillPlaceholders = function() {
	var shownToggle = function() {
		var value = !!this.value;

		if(this.tagName.toLocaleLowerCase() == 'select') {
			value = !!Array.prototype.slice.call(this.children).filter(function(option) {
				return !!option.selected && option.value;
			}).map(function(v,i,a) {
				return v.value;
			}).length;
		}

		this.classList[value ? 'remove' : 'add']('placeholder-shown');
	};

	[].forEach.call(document.querySelectorAll('[placeholder]'), function(el) {
		el.addEventListener('change', shownToggle);
		el.addEventListener('keyup', shownToggle);
		shownToggle.apply(el);
	});
});