jQuery(function($) {
	var $html = $('html').removeClass('no-js');

	$html.deepLinks();
	$('form input[id^="your-name_"]').attr('autocomplete', 'chrome-off');

	$.elementOffset = function($element) {
		return $element.offset().top - 16 * 4.5;
	};

	var polyfillMultiselects = function() {
		if(!$.supports.touch) {
			$('select[multiple]').multiselect().on('focus blur reset', function(e) {
				$(this).parents('.pagecreator_formelement').eq(0).css('z-index', e.type == 'focus' ? 11 : '');

				if(e.type == 'reset') {
					$(this).next().find('.active').removeClass('active');
					setTimeout(function() {
						$(this).val('');
					}.bind(this), 10);
				}
			});
		}
	};
	polyfillMultiselects();

	// ADD ALL YOUR CUSTOM JS THINGS AFTER THIS LINE
	$html.on({
		mouseup: function() {
			if($(this).parent().next().is('.active')) {
				setTimeout(function() {
					$('body').click();
				}, 100);
			}
		}
	}, '.multiselect-input');
	/**
	 * Open menu and focus search on #search links
	 */
	$html.on({
		click: function() {
			$('#hamburgerToggle').click();

			// create invisible dummy input to receive the focus first
			var fakeInput = document.createElement('input');
			fakeInput.setAttribute('type', 'text');
			fakeInput.style.position = 'absolute';
			fakeInput.style.opacity = 0;
			fakeInput.style.height = 0;
			fakeInput.style.fontSize = '16px'; // disable auto zoom

			// you may need to append to another element depending on the browser's auto
			// zoom/scroll behavior
			document.body.prepend(fakeInput);

			// focus so that subsequent async focus will work
			fakeInput.focus();

			setTimeout(function() {
				$('#s_1').get(0).focus();
				// cleanup
				fakeInput.remove();
			}, 250);

			return false;
		}
	}, 'a[href="#search"]');

	/**
	 * Let each icon in navigation open the search toolbar
	 */
	$html.on({
		click: function() {
			if($html.is('[data-search]')) {
				$html.removeAttr('data-search');
			}
			else {
				$html.attr('data-search', 1);
				$('[type="search"]').get(0).focus();
			}

			return false;
		}
	}, '.container-header .navigation .menu .menu-item:nth-child(7) > a');

	/**
	 * Make tags deletable and refresh filter
	 */
	$html.on({
		click: function(e) {
			var $link = $(this),
				$tag = $link.closest('.tags__item'),
				$connected_field = $('[id^="' + $link.data('field') + '"]').eq(0);

			if($connected_field.length == 0) {
				$connected_field = $('[id^="' + $link.data('field').split('__')[0] + '"]').eq(0);
			}

			$tag.fadeOut(function() {
				$(this).remove();
			});

			switch($connected_field.prop('type')) {
				case 'radio':
				case 'checkbox':
					$connected_field.each(function() {
						$(this).attr('checked', false).change();
					});
					break;
				default:
					if($connected_field.is('select')) {
						$($connected_field.get(0).selectedOptions).map(function() {
							if(this.text == $tag.text().replace($link.text(), '').replace($tag.find('strong').text(), '').trim()) {
								$connected_field.data('multiselect').deselect(this.value);
							}
						});
						$connected_field.change();
					}
					else {
						$connected_field.each(function() {
							$(this).val('').change().click();
						});
					}
			}

			return false;
		}
	}, '.tags__list a[data-field]');

	/**
	 * Reload cards placeholder element
	 */
	$html.on({
		ready: function() {
			var card = $(this).find('[data-slider]').get(0),
				height = $('.posttypelist_default--contact.posttype-member').prev().prev().find('.grid').outerHeight();

			if(height) {
				$(document).scrollTop($(this).offset().top - ($('.container-header').height() + height + 40));
			}

			if(card) {
				gridCards(card);
				$(this).find('[data-slider]').slider();
			}
		}
	}, '.pagecreator_posttypelist, .pagecreator_termlist');

	/**
	 * Reload placeholders helper and multiselect helper and markers
	 */
	$html.on({
		ready: function() {
			polyfillMultiselects();
			polyfillPlaceholders();
			$(this).trigger('clusterize');
		},
		clusterize: function() {
			var $map = $(this).next('.pagecreator_contact').children('.map');

			if(!$(this).find('[data-field="city"]').val()) {
				$map.parent().addClass('pagecreator_contact--hidden').removeClass('pagecreator_contact--visible');
			}
			else {
				$map.parent().addClass('pagecreator_contact--visible').removeClass('pagecreator_contact--hidden');

				var akmaps = $map.data('akmaps');

				setTimeout(function() {
					if(akmaps && akmaps.clusterer) {
						akmaps.clusterer.clearMarkers();
						akmaps.clusterer.addMarkers(akmaps.markers);
						akmaps.clusterer.fitMapToMarkers();
						akmaps.clusterer.setStyles([
							{
								url: akmaps.config.marker.icon.url,
								width: 45,
								height: 55,
								anchorText: [12,0],
								textColor: 'transparent',
								textSize: 20,
								fontWeight: 900,
								className: 'article__pin--counted'
							}
						]);
						setTimeout(function() {
							var clusters = akmaps.clusterer.getClusters();
							clusters.map(function(cluster, i) {
								$(cluster.clusterIcon_.div_).attr('data-index', i+1);
								var marker = cluster.getMarkers()[0];
								var geo = [Math.round(marker.position.lat() * 1000000000000) / 1000000000000, Math.round(marker.position.lng() * 1000000000000) / 1000000000000].join(',');

								$('[data-geo="' + geo + '"]').find('.article__pin').text(i+1);
							});
						}, 300);
					}
				}, 300);
			}
		}
	}, '.pagecreator_filter')
		.find('.pagecreator_filter').each(function() {
			$(this).trigger('clusterize');
		}).filter('[data-fetch="1"]').trigger('submit');

	/**
	 * Google Maps helper to overlay whole world with color except germany
	 */
	if(typeof(google) != 'undefined') {
		var overlaySVG;
		SVGOverlay.prototype = new google.maps.OverlayView();
		/** @constructor */
		function SVGOverlay(bounds, image, map) {
			// Initialize all properties.
			this.bounds_ = bounds;
			this.image_ = image;
			this.map_ = map;
			this.div_ = null;
			this.setMap(map);
		}

		SVGOverlay.prototype.onAdd = function() {
			var div = document.createElement("div");
			div.style.borderStyle = 'none';
			div.style.borderWidth = '0px';
			div.style.position = 'absolute';

			// Load the inline svg element and attach it to the div.
			var svg = this.image_;
			svg.style.width = '100%';
			svg.style.height = '100%';


			div.appendChild(svg);
			this.div_ = div;
			// Add the element to the "overlayLayer" pane.
			var panes = this.getPanes();
			panes.overlayLayer.appendChild(div);
		};

		SVGOverlay.prototype.draw = function() {
			// We use the south-west and north-east
			// coordinates of the overlay to peg it to the correct position and size.
			// To do this, we need to retrieve the projection from the overlay.
			var overlayProjection = this.getProjection();

			// Retrieve the south-west and north-east coordinates of this overlay
			// in LatLngs and convert them to pixel coordinates.
			// We'll use these coordinates to resize the div.
			var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
			var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

			// Resize the image's div to fit the indicated dimensions.
			var div = this.div_;
			div.style.left = sw.x + 'px';
			div.style.top = ne.y + 'px';
			div.style.width = (ne.x - sw.x) + 'px';
			div.style.height = (sw.y - ne.y) + 'px';
		};
	}

	$('.map').one({
		'akpc:init_map': function() {
			var $container = $(this),
				akmaps = $container.data('akmaps'),
				gmap = akmaps.gmap,
				popup = new google.maps.InfoWindow({
					pixelOffset: new google.maps.Size(0, -22.5),
					maxWidth: 480
				});

			if(false && $container.is(':only-child') && !$container.parent().prev().is('.pagecreator_filter')) {
				$.get(dhiApiSettings.theme + '/dist/germany.geojson', function(data) {
					var coordinates = $.parseJSON(data).features[0].geometry.coordinates.map(function(sc) {
						return [].concat.apply([], sc.map(function(c) {
							return c.map(function(coordinate) {
								return new google.maps.LatLng(coordinate[1], coordinate[0]);
							})
						}));
					});

					new google.maps.Polygon({
						paths: [
							[
								new google.maps.LatLng(-85.1054596961173, 0),
								new google.maps.LatLng(-85.1054596961173, 180),
								new google.maps.LatLng(85.1054596961173, 180),
								new google.maps.LatLng(85.1054596961173, -180),
								new google.maps.LatLng(-85.1054596961173, -180)
							]
						].concat(coordinates),
						strokeWeight: 0,
						fillColor: '#21ADBE',
						fillOpacity: 0.35,
						map: gmap
					});
				});
			}

			if(!akmaps.config.latlngs || akmaps.config.latlngs.length <= 1) {
				if(akmaps.markers.length == 1) {
					gmap.setCenter(akmaps.markers[0].position);
				}
				else {
					gmap.fitBounds(new google.maps.LatLngBounds(
						new google.maps.LatLng(47.279229,5.832617),
						new google.maps.LatLng(55.053203,15.029297)
					));
				}
			}

			if(akmaps.markers && akmaps.markers.length > 0) {
				$.each(akmaps.markers, function(i,marker) {
					google.maps.event.clearListeners(marker, 'click');
					marker.addListener('mouseover', function() {
						if(!this.popupData) {
							var that = this;
							$.getJSON(dhiApiSettings.rest_root + 'akpc/member', {nonce: dhiApiSettings.nonce, id: this.extra[2]}, function(data) {
								that.popupData = data;
								if(popup.anchor == that) {
									popup.setContent(data);
								}
							});
						}
					});
					marker.addListener('click', function() {
						popup.setContent(this.popupData);
						popup.open(gmap, marker);
					});
				});
				if(akmaps.markers.length > 1) {
					akmaps.clusterer = new MarkerClusterer(gmap, akmaps.markers, {
						zoomOnClick: false,
						minimumClusterSize: 1,
						styles: [
							{
								url: akmaps.config.marker.icon.url,
								width: 45,
								height: 55,
								anchorText: [12,0],
								textColor: 'white',
								textSize: 20,
								fontWeight: 900
							}
						]
					});

					akmaps.clusterer.addListener('clusterclick', function(cluster) {
						var ids = cluster.getMarkers().map(function(marker) {
								return marker.extra[2];
							}),
							marker = cluster.getMarkers().map(function(marker) {
								return marker.extra.slice(0,2).join(',');
							});
						marker = marker.filter(function(v, i, a){return a.indexOf(v) === i});

						if(marker.length > 1) {
							gmap.setCenter(cluster.getCenter());
							gmap.fitBounds(cluster.getBounds());
						}
						else {
							var first = cluster.getMarkers()[0];
							$.getJSON(dhiApiSettings.rest_root + 'akpc/member', {nonce: dhiApiSettings.nonce, id: ids}, function(data) {
								popup.setContent(data);
								popup.setPosition(first.position);
								popup.open(gmap, first);
							});
						}
					});
				}
			}

			gmap.setOptions({
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_TOP
				}
			});
		}
	}).filter(function() {
		return !!$(this).data('akmaps');
	}).trigger('akpc:init_map');

	// this could throw an error, do it last
	if($('input[data-field="city"]').length) {
		geolocator.config({
			language: "de"
		});
		geolocator.locate({
			fallbackToIP: true,     // fallback to IP if Geolocation fails or rejected
		}, function (err, location) {
			if (err) return err;

			$('input[data-field="city"]').each(function() {
				if(!$(this).val()) {
					$(this)
						.val(location.address.city)
						.change();
					$(this).removeClass('placeholder-shown');
				}
			})
		});
	}
});