(function($) {
	$.fn.responsiveTable = function(options) {

		return $(this).each(function() {
			var $this = $(this),
				headertext = [],
				$headers = $this.find('thead'),
				$tablebody = $this.find('tbody'),
				i, j, row, col;

			$headers.each(function(i) {
				headertext[i]=[];

				$(this).find('th').each(function() {
					headertext[i].push($(this).text().replace(/\r?\n|\r/,"").trim());
				});
			});

			if($headers.length) {
				$tablebody.each(function(h) {
					$(this).children().each(function(i) {
						$(this).children().each(function(j) {
							$(this).attr('data-th', headertext[h][j]);
						});
					});
				});
			}
		});
	};

	$(function() {
		$('[data-responsive-table]').responsiveTable();
	});
})(jQuery);