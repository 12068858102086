(function($) {
	$.fn.offCanvasMenu = function() {
		this.each(function(i, offCanvas) {
			var $menu = $(offCanvas),
				$toggle = $menu.find('input[type="checkbox"]').eq(0);

			if($menu.width() != $(window).width()) {
				function preventDefault(e) {
					e = e || window.event;
					if(e.preventDefault)
						e.preventDefault();
					e.returnValue = false;
				}

				function preventDefaultForScrollKeys(e) {
					if(keys[e.keyCode]) {
						preventDefault(e);
						return false;
					}
				}

				function disableScroll() {
					if(window.addEventListener) // older FF
						window.addEventListener('DOMMouseScroll', preventDefault, false);
					window.onwheel = preventDefault; // modern standard
					window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
					window.ontouchmove = preventDefault; // mobile
					document.onkeydown = preventDefaultForScrollKeys;
				}

				function enableScroll() {
					if(window.removeEventListener)
						window.removeEventListener('DOMMouseScroll', preventDefault, false);
					window.onmousewheel = document.onmousewheel = null;
					window.onwheel = null;
					window.ontouchmove = null;
					document.onkeydown = null;
				}

				var keys = {37: 1, 38: 1, 39: 1, 40: 1},
					scrollmenu = false,
					$stage = $menu.find('.navigation > nav'),
					position = parseInt($menu.css('left')) == 0 ? 'left' : 'right',
					margin = ($(window).width() - $menu.parent().width()) / 2 - parseInt($('body').css('padding-'+position));

				$menu.parent().css('margin-'+position, margin);

				$(window).on({
					resize: function() {
						if($menu.width() != $(window).width()) {
							margin = ($(window).width() - $menu.parent().width()) / 2 - parseInt($('body').css('padding-'+position));

							if(!$toggle.is(':checked') && $toggle.is(':visible')) {
								$menu.parent().css('margin-'+position, margin);
							}
						}
					}
				});

				$stage.on({
					mouseenter: function() {
						scrollmenu = true;
					},
					mouseleave: function() {
						scrollmenu = false;
					}
				});

				$toggle.on({
					change: function() {
						if($(this).is(':checked') || !$(this).is(':visible')) {
							$menu.parent().css('margin-'+position, $menu.find('.navigation').width());
						}
						else {
							$menu.parent().css('margin-'+position, margin);
						}
					}
				}).change();

				$(document).on({
					'wheel keydown': function(e) {
						if(scrollmenu) {
							var delta = e.originalEvent.wheelDelta || -1 * e.originalEvent.deltaY,
								margin = parseInt($stage.css('margin-top')),
								height = $stage.parent().height() - $stage.get(0).scrollHeight;

							margin = margin + (delta < 0 ? -Math.abs(delta) : Math.abs(delta));

							if(margin > 0) {
								margin = 0;
							}

							if(height > 0) {
								height = 0;
							}

							if(margin < height) {
								margin = height;
							}

							$stage.css('margin-top', margin);

							disableScroll();
						}
						else {
							enableScroll();
						}
					}
				});
			}
		});

		return this;
	};

	$(function() {
		$('[data-offcanvas-menu]').offCanvasMenu();
	});
}(jQuery));